var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-3/6 xxl:w-1/3 m-0",attrs:{"id":"4_applicant-onboarding-birth-data"}},[_c('div',{staticClass:"vx-row no-gutter items-center justify-center p-6"},[_c('vx-card',{staticClass:"login-card py-3 px-3"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('vs-progress',{attrs:{"percent":60,"height":10,"color":"primary"}}),_c('div',{staticClass:"vx-col sm:w-full"},[_c('div',{staticClass:"login-tabs-container"},[_c('div',{staticClass:"vx-row m-0"},[_c('div',{staticClass:"vx-col w-full p-0",staticStyle:{"min-height":"200px"}},[_c('div',{staticClass:"px-6 pt-5 pb-5"},[_c('div',{staticClass:"vx-row mb-4 center"},[_c('div',{staticClass:"vx-col w-full"},[_c('p',{staticClass:"mb-2"},[_vm._v("PASO 3 DE 5")]),_c('p',{staticClass:"text-2xl card-sub-title"},[_vm._v("¿Cúando y dónde naciste?")])])]),(_vm.isMounted)?_c('div',{staticClass:"main-form mt-base"},[_c('div',{staticClass:"vx-row"},[_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Fecha de nacimiento")]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"w-full",attrs:{"type":"date","name":"birthDate","danger":_vm.hasError('birthDate'),"danger-text":_vm.errorText('birthDate'),"success":_vm.isSuccess('birthDate'),"format":"dd/MM/yyyy","max":_vm.disabledDates.from},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}})],1),_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Estado de nacimiento")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"vs-custom",class:{
                                                    'has-error': _vm.hasError('birthState'),
                                                    'is-success': _vm.isSuccess('birthState'),
                                                    },attrs:{"name":"birthState","options":_vm.collections.states,"reduce":(item) => item.value,"clearable":false,"placeholder":"Selecciona una opción","searchable":false},model:{value:(_vm.birthState),callback:function ($$v) {_vm.birthState=$$v},expression:"birthState"}}),(_vm.hasError('birthState'))?_c('v-error',{attrs:{"error":_vm.errorText('birthState')}}):_vm._e()],1)]),(_vm.birthState == _vm.birthStateIdForeign)?_c('div',{staticClass:"vx-row"},[_c('div',{class:_vm.colClass},[_c('label',{staticClass:"vs-input--label"},[_vm._v("País de nacimiento *")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"vs-custom",class:{
                                                    'has-error': _vm.hasError('originCountry'),
                                                    'is-success': _vm.isSuccess('originCountry'),
                                                    },attrs:{"name":"originCountry","options":_vm.collections.countries,"reduce":(item) => item.value,"clearable":false,"placeholder":"Selecciona una opción","searchable":false},model:{value:(_vm.originCountry),callback:function ($$v) {_vm.originCountry=$$v},expression:"originCountry"}}),(_vm.hasError('originCountry'))?_c('v-error',{attrs:{"error":_vm.errorText('originCountry')}}):_vm._e()],1),_c('div',{class:_vm.colClassFull},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Nacionalidad *")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.requiredRules),expression:"requiredRules"}],staticClass:"vs-custom",class:{
                                                    'has-error': _vm.hasError('citizenship'),
                                                    'is-success': _vm.isSuccess('citizenship'),
                                                    },attrs:{"name":"citizenship","options":_vm.collections.countries,"reduce":(item) => item.value,"clearable":false,"placeholder":"Selecciona una opción","searchable":false},model:{value:(_vm.citizenship),callback:function ($$v) {_vm.citizenship=$$v},expression:"citizenship"}}),(_vm.hasError('citizenship'))?_c('v-error',{attrs:{"error":_vm.errorText('citizenship')}}):_vm._e()],1)]):_vm._e(),(_vm.errorMssg)?_c('vs-alert',{staticClass:"mb-5",attrs:{"icon-pack":"feather","icon":"icon-alert-triangle","color":"danger"}},[_c('span',{staticClass:"font-regular"},[_vm._v(_vm._s(_vm.errorMssg))])]):_vm._e(),_c('div',{staticClass:"vx-row justify-center"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-full w-full"},[_c('vs-button',{staticClass:"w-full mt-4",attrs:{"id":"4_birth_data_confirm_btn","color":"dark"},on:{"click":_vm.saveDataForm}},[_vm._v("Continuar")])],1)])],1):_vm._e()])])])])])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }