<template>
    <div id="6_syntage" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/4 xl:w-3/6 xxl:w-3/6 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="80" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="mb-2">PASO 4 DE 4</p>
                                                <p class="card-sub-title text-2x1">Vincula tu cuenta del SAT (CIEC)</p>
                                                <p class="text-sm">
                                                    Validaremos tu información de forma segura y confidencial. <br>
                                                    Tu información se mantiene privada.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form syntage">
                                            <img :src="syntageLogo" alt="RG + SAT" class="fix-margin mb-4 w-8/10">
                                            <div class="vx-row mt-base">
                                                <!-- <div class="vx-col w-full text-center">
                                                    <span class="bold">Confirma tus datos</span> <br>
                                                </div> -->
                                                <div class="vx-col sm:w-full">
                                                    <p class="mt-2 mb-6 text-center black bold text-2xl">
                                                        <!-- <span class="bold mb-2">RFC de la empresa:</span> <br> -->
                                                        <template v-if="!isEditingRFC">
                                                            {{ business.rfc }}
                                                            <feather-icon @click.stop="editRfc" icon="EditIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer" class="ml-2 pt-1 rfc" />
                                                        </template>
                                                        <template v-else>
                                                            <div class="flex mt-1 justify-center">
                                                                <vs-input 
                                                                    @input="(val) => (currentRfc = currentRfc.toUpperCase())"
                                                                    class="sm: w-full md:w-1/4"
                                                                    name="currentRfc"
                                                                    v-validate="requiredRules"
                                                                    v-model.lazy="currentRfc"
                                                                    :danger="hasError('currentRfc')"
                                                                    :danger-text="errorText('currentRfc')"
                                                                    :success="isSuccess('currentRfc')"
                                                                    icon-pack="feather"
                                                                    :placeholder="requiredPlaceholder"
                                                                />
                                                                <feather-icon @click.stop="saveRfc()" icon="SaveIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer rfc" class="ml-2 pt-1" />
                                                            </div>
                                                        </template>
                                                    </p>
                                                </div>
                                                <!-- <div class="vx-col sm:w-full md:w-1/2">
                                                    <p class="mt-2 mb-4 text-center">
                                                        <span class="bold mb-2">Tel. del representante legal:</span> <br>
                                                        <template v-if="!isEditingPhone">
                                                            {{ currentPhoneNumber }}
                                                            <feather-icon @click.stop="editPhone" icon="EditIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer" class="ml-2 pt-1" />
                                                        </template>
                                                        <template v-else>
                                                            <div class="flex mt-1">
                                                                <div class="flex">
                                                                    <vue-country-code
                                                                        name="dialCode"
                                                                        class="vs-custom"
                                                                        data-vv-name="dialCode"
                                                                        :enableSearchField="true"
                                                                        searchPlaceholderText="Buscar código de país"
                                                                        :enabledCountryCode="true"
                                                                        @onSelect="onSelect"
                                                                        :preferredCountries="['MX']"
                                                                        :danger="hasError('dialCode')"
                                                                        :danger-text="errorText('dialCode')"
                                                                        :success="isSuccess('dialCode')">
                                                                    </vue-country-code>
                                                                    <vs-input
                                                                        class="w-full"
                                                                        label=""
                                                                        name="phone"
                                                                        type="tel"
                                                                        v-validate="'required|numeric'"
                                                                        v-model.lazy="currentPhone"
                                                                        :danger="hasError('phone')"
                                                                        :danger-text="errorText('phone')"
                                                                        :success="isSuccess('phone')"
                                                                        icon-pack="feather"
                                                                        placeholder="(Requerido)"
                                                                    />
                                                                </div>
                                                                <feather-icon @click.stop="changeUserPhone()" icon="SaveIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer phone" class="ml-2 pt-1" />
                                                            </div>
                                                        </template>
                                                    </p>
                                                </div> -->
                                            </div>
                                            <div class="vx-row content-center">
                                                <div :class="colClass" class="mb-base">
                                                    <label class="vs-input--label bold">Contraseña SAT (CIEC)</label>
                                                    <vx-input-group class="w-full">
                                                        <vs-input v-model.lazy="password"
                                                            v-validate="requiredRules" :danger="hasError('password')"
                                                            :danger-text="errorText('password')"
                                                            :type="passInputType"
                                                            name="password" />

                                                        <template slot="append">
                                                            <div class="append-text btn-addon" style="height:40px;">
                                                                <vs-button color="dark" @click="togglePassInputType()"
                                                                    icon-pack="feather"
                                                                    :icon="passInputIcon"></vs-button>
                                                            </div>
                                                        </template>
                                                    </vx-input-group>
                                                    <!-- <span class="no-password">¿No tienes tu contraseña?</span> -->
                                                </div> 
                                                <!-- <div :class="colClass" class="mb-base">
                                                    <label class="vs-input--label bold">Código de verificación</label>
                                                    <vx-input-group class="w-full">
                                                        <vs-input 
                                                            v-model.lazy="nipRequested"
                                                            v-validate="requiredRules" 
                                                            :danger="hasError('nip')"
                                                            :danger-text="errorText('nip')"
                                                            type="text"
                                                            name="nip" />

                                                    </vx-input-group>
                                                    <span v-if="!nipIsCreated" @click.stop="doRequestCDCNip()" class="no-password">Solicitar código de verificación vía sms</span>
                                                    <span v-if="nipIsCreated && !showWhatsapp" class="no-password">Puedes solicitar un nuevo código en {{ whatsappTime }} segundos</span>
                                                    
                                                    <div v-if="showWhatsapp" class="mb-2 mt-2">
                                                        <p class="bold mb-1 text-sm">
                                                            ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
                                                        </p>
                                                        <div class="clickable-img" @click.stop="doRequestCDCNip(true)">
                                                            <vs-chip class="mr-2 bold">Enviar Whatsapp a {{ currentPhoneData.country_calling_code }}{{
                                                    anonymousFormatPhone(currentPhoneNumber) }}</vs-chip>
                                                        </div>
                                                        <div class="clickable-img" @click.stop="doRequestCDCNip()">
                                                            <vs-chip class="mr-2 bold">Enviar SMS a {{currentPhoneData.country_calling_code }}{{
                                                    anonymousFormatPhone(currentPhoneNumber) }}</vs-chip>
                                                        </div>
                                                    </div>
                                                </div>   -->
                                                <div class="vx-col md:w-10/12 mb-base account-button mt-2">
                                                    <div class="flex">
                                                        <vs-checkbox v-model="legal"
                                                            name="legal" v-validate="requiredRules"
                                                            :danger="hasError('legal')"
                                                            :danger-text="errorText('legal')"
                                                            :success="isSuccess('legal')"></vs-checkbox>
                                                        <p class="text-sm text-justify">
                                                            <span class="bold">Autorización de consulta al Buró de Crédito</span> <br>
                                                            Estoy de acuerdo en permitir que RedGirasol (RED GIRASOL SAPI DE CV, INSTITUCION DE FINANCIAMIENTO COLECTIVO) acceda a mi historial en 
                                                            cualquier sociedad de información crediticia. Entiendo la información 
                                                            requerida, su propósito y otorgo permiso para consultas regulares. Esta 
                                                            autorización tendrá validez durante tres años o mientras mantengamos
                                                            nuestra relación. <br>
                                                            <!-- Al hacer clic en “Conectar cuenta” firmas electrónicamente nuestro 
                                                            <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(comisionMercantilUrl)">Contrato de Comisión Mercantil</a> (lo cual nos permite levantar fondos para tu proyecto). -->
                                                        </p>
                                                    </div>
                                                </div> 
                                                <!-- <div class="vx-col md:w-10/12 mb-8 account-button">
                                                    <div class="flex mb-4">
                                                        <vs-checkbox v-model="advertisingChecked"
                                                            name="advertisingChecked" v-validate="requiredRules"
                                                            :danger="hasError('advertisingChecked')"
                                                            :danger-text="errorText('advertisingChecked')"
                                                            :success="isSuccess('advertisingChecked')"></vs-checkbox>
                                                        <p class="text-sm text-justify">
                                                            Autorizo a RedGirasol que me envíe publicidad y que use mis datos para mejorar mi experiencia y recibir promociones que se ajusten a mis necesidades.
                                                        </p>
                                                    </div>
                                                </div>                                       -->
                                            </div>
                                        
                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="justify-center vx-row">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                        >Atrás</vs-button>
                                                </div> -->
                                                <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button :disabled="isDisable" id="4_stakeholders_confirm_btn" class="w-full" color="dark" @click="saveDataForm">Conectar cuenta</vs-button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center text-base">
                                            <p class="no-password">
                                                ¿Por qué te pedimos la cuenta del SAT (CIEC)?
                                            </p>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import { ScoreService } from "@/services/score/ScoreService.js"

const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";

export default {
    name: "ApplicantOnboardingSyntage",
    props: ["onboardingStepData"],
    mixins: [inputHelper, formatHelper],
    data: () => ({
        requiredRules: 'required',
        colClass: "vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-5 mt-2",
        passInputPassMode: true,
        legal: false,
        errorMssg: null,
        isMounted: false,
        syntageLogo: "https://cdn.redgirasol.com/applicants/syntage_schema.png",
        password: null,
        requiredPlaceholder: "(Requerido)",
        isEditingRFC: false,
        isEditingPhone: false,
        country_calling_code: null,
        currentPhone: null,
        currentRfc: null,
        nipIsCreated: false,
        nipRequested: null,
        applicantContractType: 4,
        applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
        advertisingChecked: true,
        showWhatsapp: false,
        whatsappTime: 30,
        nipIsCreated: false,
        lastSatProfile: null,
    }),
    async mounted(){
        if(this.satProfile != null) {
            if(this.satProfile.credential_status == "error") {
                this.errorMssg = "No hemos podido validar tu cuenta del SAT, por favor verifica tus datos e intenta de nuevo.";
            }
            if(this.satProfile.credential_status == "invalid") {
                this.errorMssg = "Parece que la contraseña SAT (CIEC) es incorrecta, por favor verifica tus datos e intenta de nuevo.";
            }
        }
        this.isMounted = true;
    },
    computed: {
        comisionMercantilUrl(){
            return `${this.WebSiteUrl}/${this.applicantContractUrl}`;
        },
        passInputType() {
            return this.passInputPassMode ? "password" : textType;
        },
        passInputIcon() {
            return this.passInputPassMode ? iconEye : iconEyeOff;
        },
        business() {
            return this.onboardingStepData.user.business
        },
        business_owner() {
            return this.business.personal;
        },
        business_address() {
            return this.business.address;
        },
        currentPhoneData() {
            return this.business_owner.phone;
        },
        currentPhoneNumber() {
            return this.currentPhoneData.phone
        },
        // isDisable() {
        //     return this.isEditingPhone || this.isEditingRFC || this.password == null || !this.legal || this.nipRequested == null
        // },
        isDisable() {
            return  this.isEditingRFC || this.password == null || !this.legal;          
        },
        satProfile() {
            return this.business.last_sat_profile;
        },
    },
    methods: {
        onSelect({ dialCode }) {
            this.country_calling_code = `+${dialCode}`;
        },
        doRequestSaveData() {

        },
        async saveDataForm() {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                return;
            }

            if(!this.legal) {
                this.missingFieldsNotif();
                return;
            }
            
            const payload = {
                rfc: this.business.rfc,
                password: this.password,
                type: "ciec",
                // applicant_id: this.ApplicantId,
                // verification_code: this.nipRequested,
                // advertising_checked: this.advertisingChecked,
                // contractType: this.applicantContractType
            }
            
            try {
                this.showLoading(true);
                const res = await axios.post(`/api/sat/credential`, payload);
                console.log(res);
                this.lastSatProfile = res.data;
                // await axios.put(`/api/applicant-onboarding/put/update-sat-profile`, payload);
                // await this.requestScorePM();
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (error) {
                this.showLoading(false);
                if(error.response.data.error != null && error.response.data.error != undefined) {
                    this.errorMssg = `${error.response.data.error}, contáctanos a través del chat para darte una solución.`;
                } else {
                    this.errorMssg = "Tenemos problemas para procesar tu solicitud, intenta más tarde o contáctanos a través del chat."
                }
            } finally {
                // await axios.put(`/api/applicant-onboarding/put/update-sat-profile`, payload);
                // await this.requestScorePM();
                // this.$emit("updated", 1);
            }
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            return r1;
        },
        editRfc() {
            this.currentRfc = this.business.rfc;
            this.isEditingRFC = true;
        },
        async saveRfc() {
            let validRfc = await this.isValidRfcFormatByPersonType(this.currentRfc, true);
            if (!validRfc) {
                return this.missingFieldsNotif(null, "La estructura de la clave RFC es incorrecta.");
            }
            this.showLoading(true);
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    business: {
                        rfc: this.currentRfc,
                        only_rfc: true
                    }
                };
                await axios.put(
                    `/api/applicant-onboarding/put/update-business`,
                    payload
                );
                await this.$emit("updated", 1);
                this.currentRfc = null;
                this.isEditingRFC = false;
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        editPhone() {
            this.currentPhone = this.currentPhoneNumber;
            this.isEditingPhone = true;
        },
        async changeUserPhone() {
            if(this.currentPhone == null) {
                this.missingFieldsNotif();
                return;
            }
            this.showLoading(true, "Cambiando el teléfono...");
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    phone_data: {
                        phone_id: this.currentPhoneData.id,
                        country_calling_code: this.country_calling_code,
                        phone: this.currentPhone
                    },
                };
                await axios.put(
                    "/api/applicant-onboarding/put/update-applicant-phone",
                    payload
                );

                this.saveSuccessNotif(null, "Teléfono cambiado exitosamente. Te enviamos un código de verificación que usarás a continuación.");
                await this.$emit("updated", 1);
                this.currentPhone = null;
                this.isEditingPhone = false;
                this.nipIsCreated = true;
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        async doRequestCDCNip(isWhatsapp = true)
        {
            try {
                this.showLoading(true, "Enviando código de verificación...")
                const res = await axios.post(`/api/v2/projects/${this.UserId}/send-cdc-nip?whatsapp=${isWhatsapp}`);
                this.nipIsCreated = true;
                if (!this.showWhatsapp) {
                    this.initNipCounter(30);
                }
                this.nipCounter++;
                this.showLoading(false);
                this.saveSuccessNotif(null, `Te hemos enviado un código por mensaje de texto al siguiente número: ${this.currentPhoneData.country_calling_code} ${this.anonymousFormatPhone(this.currentPhoneNumber)}`);
            } catch (error) {
                console.log(error);
            }
        },
        initNipCounter() {
        let timer = setInterval(() => {
                --this.whatsappTime;
                if (this.whatsappTime == 0) {
                    this.showWhatsapp = true;
                    clearInterval(timer);
                }
            }, 1000);
        },
        async requestScorePM() {
            try {
                const payload = {
                    verification_code: this.nipRequested
                }
                console.log(payload);
                const response = await axios.post(`/api/applicant-onboarding/post/request-cdc-pm/${this.ApplicantId}`,  payload);
                if (!response.error) {
                    this.saveSuccessNotif();
                }
            } catch (error) {
                this.failedOperationNotif()
                console.log(error);
            }
        },
        togglePassInputType() {
            this.passInputPassMode = !this.passInputPassMode;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

.fix-margin {
    margin: 1rem auto 0 auto !important;
}

.syntage {
    border: 2px solid #C8C8C8;
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    background-color: #c8c8c814;
}

.no-password {
    color: #13640C;
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
}
.content-center {
    place-content: center;
}

span.feather-icon .rfc {
    margin-top: -.5rem;
}
span.feather-icon .phone {
    margin-top: 0rem;
}
</style>