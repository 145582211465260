<template>
    <div class="vx-col w-full">
        <div class="justify-center" :class="needUser ? 'vx-row' : ''">
            <div v-if="!showFormCode" class="referral-code">
                <p v-if="!hasReferralCode">
                    ¿Te recomendó algún promotor o proveedor? <a href="#" @click.stop="showFormCode = true">Ingresa su código aquí.</a>
                </p>
                <p v-else>
                    Te estás registrando con la referencia del promotor / proveedor <span class="bold">{{ referredSupplier.tradename }}</span>.
                </p>
            </div>
            <div v-else class="referral-code">
                <p class="mb-2">Compártenos el código de referencia del promotor / proveedor.</p>
                <div class="flex content-center">
                    <vs-input v-model.lazy="code" placeholder="Código de referencia" class="mr-4"></vs-input>
                    <vs-button @click.stop="validateReferredCode()">Verificar</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            showFormCode: false,
            code: null,
            supplierReferralInfo: null

        }),
        props: {
            needUser: {
                type: Boolean,
                required: false,
                default: false
            },
            userData: {
                type: Object,
                required: false
            },
            codeProp: {
                type: String,
                required: false
            }
        },
        computed: {
            hasReferralCode() {
                if(this.needUser) {
                    return this.userData.referred_supplier_id != null;
                } else {
                    return this.supplierReferralInfo != null;
                }
            },
            referredSupplier() {
                if(this.needUser) {
                    return this.userData.referred_supplier;
                } else {
                    return this.supplierReferralInfo;
                }
            }
        },
        beforeMount() {
            if(!this.needUser) {
                this.validateReferredCode();
            }
        },
        methods: {
            async validateReferredCode() {
                try {
                    if(this.code != null || this.codeProp != null) {
                        this.showLoading(true);
                        // consultamos si el link sea valido
                        const res = await this.publicApiPost(`/api/loan/post/validate-supplier-code`, {
                            code: this.codeProp != null ? this.codeProp : this.code,
                            user_id: this.needUser ? this.userData.id : null
                        });

                        if (res.data.success == "true" || res.data.success == true) {
                            if(this.needUser) {
                                location.reload();
                            } else {
                                this.supplierReferralInfo = {
                                    uri: this.code,
                                    supplierId: res.data.supplier.id,
                                    tradename: res.data.supplier.tradename
                                }
                                this.showFormCode = false;
                                this.$emit('onupdated', this.supplierReferralInfo);
                            }
                        } else {
                            this.warningNotifDialog("Código de referido inválido", res.data.message);
                            // this.missingFieldsNotif("Código de referido inválido", res.data.message);
                        }
                        this.showLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                    this.showLoading(false);
                    this.failedOperationNotif();
                }
            }
        }
    }
</script>

<style>

.content-center {
    justify-content: center;
}

</style>