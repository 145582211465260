<template>
    <div id="4_stakeholders" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/5 xl:w-3/6 xxl:w-3/6 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="60" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="mb-2">PASO 3 DE 5</p>
                                                <p class="card-sub-title">¿Cuál es tu Rol en la empresa?</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base">
                                            <div class="vx-row">
                                                <div class="vx-col w-full mb-8 account-button mt-2" 
                                                v-for="(item, index) in accountTypes" :key="index">
                                                    <rg-radio class="w-full" groupName="accountType" :label="item.label"
                                                        :value="item.value" :desc="item.desc" v-model="role" />
                                                        
                                                    <!-- ACCIONES PORCENTAJE -->
                                                    <div v-if="role == 1 && index == 1">
                                                        <div :class="colClass" class="fix-margin">
                                                            <vs-input
                                                                class="w-full"
                                                                label="Porcentaje de acciones"
                                                                name="percentageValue"
                                                                v-validate="requiredRules"
                                                                v-model.number="percentageValue"
                                                                :danger="hasError('percentageValue')"
                                                                :danger-text="errorText('percentageValue')"
                                                                :success="isSuccess('percentageValue')"
                                                                icon-pack="feather"
                                                                icon="icon-percent"
                                                            />
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="vx-col w-full mb-8 account-button mt-2">
                                                    <div class="flex mb-4">
                                                        <vs-checkbox v-model="legal"
                                                            name="legal" v-validate="requiredRules"
                                                            :danger="hasError('legal')"
                                                            :danger-text="errorText('legal')"
                                                            :success="isSuccess('legal')"></vs-checkbox>
                                                        <p class="text-sm">
                                                            Confirmo que los datos registrados son verdaderos
                                                            y coinciden con la información actual de la 
                                                            empresa (Asamblea, Acta Constitutiva, etc.)
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        
                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="justify-center vx-row">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                        >Atrás</vs-button>
                                                </div> -->
                                                <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button id="4_stakeholders_confirm_btn" class="w-full mt-4" color="dark" @click="saveDataForm"
                                                        >Continuar</vs-button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";

const accountTypes = [
    { label: "Soy representante legal", value: 2, desc: null },
    { label: "Soy accionista", value: 1, desc: null }
];
export default {
    name: "ApplicantOnboardingStakeholders",
    props: ["onboardingStepData"],
    mixins: [inputHelper],
    data: () => ({
        requiredRules: 'required',
        colClass: "vx-col sm:w-full md:w-10/12 lg:w-10/12 mb-5",
        errorMssg: null,
        role: null,
        isMounted: false,
        accountTypes: accountTypes,
        percentageValue: null,
        legal: false
    }),
    async mounted(){
        this.isMounted = true;
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                return;
            }
            if(this.role == null) {
                this.missingFieldsNotif();
                return;
            }
            if(this.role == 1) { // Sí es un accionista
                if(this.percentageValue == null) {
                    this.missingFieldsNotif();
                    return;
                } else if(this.percentageValue < 10) {
                    // this.failedOperationNotif("No puedes continuar.", "Para continuar con la solicitud de crédito, necesitamos que un accionista con más del 10% de acciones de la empresa o al representante legal.");
                    this.errorMssg = "Para continuar con la solicitud de crédito, necesitamos que un accionista con más del 10% de acciones de la empresa o al representante legal.";
                    return;
                } else if(this.percentageValue > 100) {
                    // this.failedOperationNotif("No puedes continuar.", "El porcentaje de acciones no puede ser mayor al 100%.");
                    this.errorMssg = "El porcentaje de acciones no puede ser mayor al 100%";
                    return;
                }
            }
            if(!this.legal) {
                this.missingFieldsNotif(null, "Para continuar debes confirmar que los datos registrados son verdaderos y coinciden con la información actual de la empresa (Asamblea, Acta Constitutiva, etc.)");
                return;
            }

            try {
                this.showLoading(true);
                const payload = {
                    applicant_id: this.ApplicantId,
                    role: this.role,
                    percent: this.percentageValue,
                }
                await axios.post(`/api/applicant-onboarding/post/main-user/save-role`, payload);
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (error) {
                this.showLoading(false);
                this.errorMssg = "Tenemos problemas para procesar tu solicitud, intenta más tarde o contáctanos a través del chat."
                console.log(error);
            }
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            return r1;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

.fix-margin {
    margin: 1rem auto 0 auto !important;
}

</style>