<template>
    <div id="6_message" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-3/6 xxl:w-1/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4">
                                            <div class="vx-col w-full">
                                                <p class="text-2xl card-sub-title">¡Tu cuenta se ha creado con éxito! </p>
                                            </div>
                                        </div>
                                        <vs-divider color="dark"></vs-divider>
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base pl-3 pr-3">
                                            <div class="vx-row mb-4">
                                                <div class="vx-col w-full">
                                                    <p>
                                                        Ahora que tu cuenta está lista, ya puedes solicitar un crédito desde tu portal.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="vx-row justify-center">
                                                <div class="vx-col md:w-2/3 sm:w-full w-full">
                                                    <vs-button class="w-full mt-4 pl-2 pr-2" color="dark"
                                                        @click="goToHome()">Solicitar crédito</vs-button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data: () => ({

    }),
    props: {

    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        async goToHome() {
            await this.getUserInfo("client");
            await this.$router.replace({name: 'inicioSolicitante'});
        }
    },
}
</script>