<template>
    <div id="applicant-onboarding-step-page" v-if="isMounted">
        <div>
            <div class="vx-row no-gutter items-center justify-center">
                <div class="vx-col w-10/12">
                    <div class="vx-row no-gutter items-center justify-center">
                        <!-- <ApplicantOnboardingPhone 
                            v-if="currentOnboardingStep == 'pf_1_signup' || currentOnboardingStep == 'pm_1_signup'"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData"
                        /> -->
                        <!-- ONBOARDING PARA PESONA FÍSICA. -->
                        <ApplicantOnboardingGeneral 
                        v-if="currentOnboardingStep == 'pf_1_signup' || (isPFMX && currentOnboardingStep == null)"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData"
                        />
                        <ApplicantOnboardingBirthData
                            v-else-if="currentOnboardingStep == 'pf_3_name'"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData"
                        />
                        <ApplicantOnboardingIdentifiers
                            v-else-if="currentOnboardingStep == 'pf_4_birth_data'"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData" 
                        />
                        <ApplicantOnboardingCheckData
                            v-else-if="currentOnboardingStep == 'pf_5_rfc_curp'"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData" 
                        />
                        <ApplicantOnboardingMessage
                            v-else-if="currentOnboardingStep == 'pf_6_data_check' || currentOnboardingStep == 'pm_5_sat_link'"
                            :onboarding-step-data="base" 
                            @updated="getApplicantData" 
                        />
                        <!-- ONBOARDING PARA PESONA FÍSICA. -->

                        <!-- ONBOARDING PARA PERSONA MORAL -->
                            <!-- <ApplicantOnboardingGeneralMainUser 
                                v-else-if="currentOnboardingStep == 'pm_2_cellphone_checked'"
                                :onboarding-step-data="base" 
                                @updated="getApplicantData"/> -->
                        
                            <ApplicantOnboardingBusinessData 
                                v-else-if="currentOnboardingStep == 'pm_2_cellphone_checked' || (isMoral && currentOnboardingStep == null)"
                                :onboarding-step-data="base" 
                                @updated="getApplicantData"/>

                            <!-- <ApplicantOnboardingStakeholders 
                                v-else-if="currentOnboardingStep == 'pm_2_business_data'"
                                :onboarding-step-data="base" 
                                @updated="getApplicantData"/> -->

                            <ApplicantOnboardingAddress 
                                v-else-if="currentOnboardingStep == 'pm_2_business_data'"
                                :onboarding-step-data="base" 
                                :is-moral="true"
                                @updated="getApplicantData"/>
                                
                            <ApplicantOnboardingSyntage 
                                v-else-if="currentOnboardingStep == 'pm_4_business_address' && !hasValidSatProfile"
                                :onboarding-step-data="base" 
                                @updated="getApplicantData"/>

                            <ApplicantOnboardingCDCRequest 
                                v-else-if="currentOnboardingStep == 'pm_4_business_address' && hasValidSatProfile"
                                :onboarding-step-data="base" 
                                @updated="getApplicantData"/>
                        <!-- ONBOARDING PARA PERSONA MORAL -->
                    </div>
                </div>
                <SupplierReferralCode :user-data="base.user" :need-user="true"/>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicantOnboardingPhone from "./components/ApplicantOnboardingPhone.vue";
import ApplicantOnboardingGeneral from "./components/ApplicantOnboardingGeneral.vue";
import ApplicantOnboardingBirthData from "./components/ApplicantOnboardingBirthData.vue";
import ApplicantOnboardingIdentifiers from "./components/ApplicantOnboardingIdentifiers.vue";
import ApplicantOnboardingCheckData from "./components/ApplicantOnboardingCheckData.vue";
import ApplicantOnboardingMessage from "./components/ApplicantOnboardingMessage.vue";
import SupplierReferralCode from "../../../components/supplier/widgets/SupplierReferralCode.vue";
import ApplicantOnboardingGeneralMainUser from "./components/business/ApplicantOnboardingGeneralMainUser.vue";
import ApplicantOnboardingStakeholders from "./components/business/ApplicantOnboardingStakeholders.vue";
import ApplicantOnboardingBusinessData from "./components/business/ApplicantOnboardingBusinessData.vue";
import ApplicantOnboardingAddress from "./components/business/ApplicantOnboardingAddress.vue";
import ApplicantOnboardingSyntage from "./components/business/ApplicantOnboardingSyntage.vue";
import ApplicantOnboardingCDCRequest from "./components/business/ApplicantOnboardingCDCRequest.vue";

const SAT_PROFILE_STATUS = ['pending','valid'];
const PF_MEX = 1;
const PF_EXT = 2;

const requiredObjects = [
    'user.personal.address',
    'user.personal.tins',
    'user.personal.phone',
    'user.personal.bankAccounts',
    'user.business.personal',
    'user.business.personal.phone',
    'user.business.address',
    'user.business.tins',
    'user.business.phone',
    'user.business.bankAccounts',
    'user.business.stakeholders',
    'user.referredSupplier'
];

export default {
    name: "ApplicantOnboardingStepPage",
    //   mixins: [inputHelper, userIdentityHelper],
    components: {
    ApplicantOnboardingPhone,
    ApplicantOnboardingGeneral,
    ApplicantOnboardingBirthData,
    ApplicantOnboardingIdentifiers,
    ApplicantOnboardingCheckData,
    ApplicantOnboardingMessage,
    SupplierReferralCode,
    ApplicantOnboardingGeneralMainUser,
    ApplicantOnboardingStakeholders,
    ApplicantOnboardingBusinessData,
    ApplicantOnboardingAddress,
    ApplicantOnboardingSyntage,
    ApplicantOnboardingCDCRequest
},
    data() {
        return {
            showSupplierWelcomeMessage: false,
            isMounted: false,
            colClassFull: "vx-col w-full mb-5",
            base: {},
            SAT_PROFILE_STATUS: SAT_PROFILE_STATUS
        };
    },
    computed: {
        currentOnboardingStep() {
            return this.user.applicant_onboarding_step;
        },
        hasReferralCode() {
            return this.base.user.referred_supplier_id != null;
        },
        referredSupplier() {
            return this.base.user.referred_supplier;
        },
        showReferralData() {
            return this.currentOnboardingStep != 'pf_1_signup';
        },
        personType() {
            return this.base.user.person_type;
        },
        isMoral() {
            return this.personType == 0 || this.personType == 3;
        },
        isPFMX() {
            return this.personType == PF_MEX && this.personType == PF_EXT;
        },
        business() {
            return this.base.user.business;
        },
        hasValidSatProfile() {
            return this.business.last_sat_profile != null && (this.business.last_sat_profile.credential_status == 'pending' || this.business.last_sat_profile.credential_status == 'valid');
        },
    },
    async mounted() {
        this.isMounted = false;
        await this.getApplicantData();
        this.showSupplierWelcomeMessage = true;
        this.isMounted = true;
    },
    methods: {
        async getApplicantData() {
            try {

                if(this.isMounted) {
                    await this.runOnboardginStep();
                }

                // ?filter[status]=3 // ejemplo de filtro
                let params = "with[]=" + requiredObjects.join("&with[]=");
                let response = await axios.get(`/api/v1/applicant/${this.ApplicantId}?${params}`);
                this.base = response.data;
                this.user.applicant_onboarding_step = this.base.onboarding_step;
            }
            catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async runOnboardginStep() {
            try {
                await axios.post(`/api/applicant-onboarding/post/run-onboarding-validations/${this.ApplicantId}`);
            }
            catch (e) {
                this.warn(e);
                this.failed = true;
            }
        }
    }
}
</script>