<template>
    <div id="6_syntage" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/4 xl:w-3/6 xxl:w-3/6 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="80" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMounted" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="mb-2">PASO 4 DE 4</p>
                                                <p class="card-sub-title text-2x1">Consulta historial crediticio</p>
                                                <p class="text-sm">
                                                    Validaremos tu información de forma segura y confidencial. <br>
                                                    Tu información se mantiene privada.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form vx-card p-8 mb-base">
                                            <h3 class="mb-base">Autorización de consulta crediticia</h3>

                                            <div class="vx-row">
                                                <div class="vx-col sm:w-full md:w-11/12 mb-base account-button mt-2">
                                                    <div class="flex mb-2">
                                                        <vs-checkbox v-model="legal"
                                                            name="legal" v-validate="requiredRules"
                                                            :danger="hasError('legal')"
                                                            :danger-text="errorText('legal')"
                                                            :success="isSuccess('legal')"></vs-checkbox>
                                                        <p class="text-sm text-justify">
                                                            <span class="black">
                                                                Acepto el <b>Aviso de Privacidad</b> y <b>Términos y Condiciones</b> y cláusulas de medios electrónicos tales como NIP
                                                            </span>
                                                        </p>
                                                    </div>    
                                                    <p class="black text-sm text-justify">
                                                        <span class="bold black">Autorización de Consulta.</span>
                                                        <br>
                                                        <br>

                                                        Autorizo expresamente a <b>Red Girasol S.A.P.l. de C.V. Institución de Financiamiento Colectivo</b>, para llevar a cabo Investigaciones sobre mi 
                                                        comportamiento crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente Conozco la naturaleza y alcance de la 
                                                        información que se solicitara, del uso que se le dora y que se podrán realizar consultas periódicas de mi historial crediticio. 
                                                        Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy. y en su caso mientras mantengamos relación jurídica. 
                                                        Acepto que este documento quede bajo propiedad de <b>Red Girasol S.A.P.L. de C.V. Institución de Financiamiento Colectivo y/o SIC Consultada </b>
                                                        para efectos de control y cumplimiento del articulo 28 de la LRSIC
                                                        <br>
                                                        <br>Al hacer clic en “Autorizar” firmas electrónicamente nuestro 
                                                        <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(comisionMercantilUrl)">Contrato de Comisión Mercantil</a> (lo cual nos permite levantar fondos para tu proyecto).

                                                    </p>
                                                </div> 
                                            </div>
                                        
                                            <!-- <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert> -->
                                        </div>
                                        <div class="main-form vx-card p-8">
                                            <h3 class="mb-6">Solicita tu NIP</h3>

                                            <div class="vx-row">
                                                <div class="vx-col sm:w-full md:w-11/12 mb-base account-button mt-2">
                                                    <p class="black text-sm text-justify">
                                                        Recibirás un código por mensaje SMS al teléfono que registraste. Si no lo recibes después de 1 minuto, puedes solicitar un código nuevo.
                                                        <br>
                                                        <br>
                                                        <span v-if="!nipIsCreated">
                                                            Asegúrate de que el teléfono sea correcto. Después de autorizar la consulta, no será posible modificarlo.
                                                        </span>
                                                        <span v-if="nipIsCreated && !isEditingPhone">
                                                            El código se ha enviado al número <b>({{dialCode}}) {{ anonymousFormatPhone(currentPhoneNumber) }}</b> <u class="clickable-img" @click.stop="editPhone">Editar</u>
                                                        </span>
                                                        <span v-else-if="isEditingPhone">
                                                            <div class="flex mt-1">
                                                                <div class="flex">
                                                                    <vue-country-code
                                                                        name="dialCode"
                                                                        class="vs-custom"
                                                                        data-vv-name="dialCode"
                                                                        :enableSearchField="true"
                                                                        searchPlaceholderText="Buscar código de país"
                                                                        :enabledCountryCode="true"
                                                                        @onSelect="onSelect"
                                                                        :preferredCountries="['MX']"
                                                                        :danger="hasError('dialCode')"
                                                                        :danger-text="errorText('dialCode')"
                                                                        :success="isSuccess('dialCode')">
                                                                    </vue-country-code>
                                                                    <vs-input
                                                                        class="w-full"
                                                                        label=""
                                                                        name="phone"
                                                                        type="tel"
                                                                        v-validate="'required|numeric'"
                                                                        v-model.lazy="currentPhone"
                                                                        :danger="hasError('phone')"
                                                                        :danger-text="errorText('phone')"
                                                                        :success="isSuccess('phone')"
                                                                        icon-pack="feather"
                                                                        placeholder="(Requerido)"
                                                                    />
                                                                </div>
                                                                <feather-icon @click.stop="changeUserPhone()" icon="SaveIcon" svgClasses="w-5 h-5 hover:text-success stroke-current cursor-pointer phone" class="ml-2 pt-0" />
                                                            </div>
                                                        </span>
                                                            <br>
                                                            <br>
                                                        <span v-if="nipIsCreated && !showWhatsapp">
                                                            Puedes solicitar un nuevo código en <b>{{ whatsappTime }}</b> segundos
                                                        </span>

                                                        <div v-if="showWhatsapp" class="mb-2 mt-2">
                                                            <p class="bold mb-1 text-sm">
                                                                ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
                                                            </p>
                                                            <div class="clickable-img" @click.stop="doRequestCDCNip(true)">
                                                                <vs-chip class="mr-2 bold">Enviar Whatsapp a {{ currentPhoneData.country_calling_code }}{{
                                                        anonymousFormatPhone(currentPhoneNumber) }}</vs-chip>
                                                            </div>
                                                            <div class="clickable-img" @click.stop="doRequestCDCNip()">
                                                                <vs-chip class="mr-2 bold">Enviar SMS a {{currentPhoneData.country_calling_code }}{{
                                                        anonymousFormatPhone(currentPhoneNumber) }}</vs-chip>
                                                            </div>
                                                        </div>

                                                    </p>
                                                </div> 
                                            </div>
                                        
                                            <vs-alert
                                                v-if="errorMssg"
                                                icon-pack="feather"
                                                icon="icon-alert-triangle"
                                                class="mb-5"
                                                color="danger"
                                            >
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="justify-left vx-row">
                                                <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                    <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                        >Atrás</vs-button>
                                                </div> -->
                                                <div v-if="nipIsCreated" class="vx-col sm:w-full md:w-1/5 sm:w-full w-full">
                                                    <vx-input-group class="w-full">
                                                        <vs-input 
                                                            v-model.lazy="nipRequested"
                                                            v-validate="requiredRules" 
                                                            :danger="hasError('nip')"
                                                            :danger-text="errorText('nip')"
                                                            type="text"
                                                            name="nip" />

                                                    </vx-input-group>
                                                </div>
                                                <div class="vx-col sm:w-full md:w-1/4 mb-base">
                                                    <vs-button v-if="!nipIsCreated" @click.stop="doRequestCDCNip()" :disabled="nipIsCreated" id="4_stakeholders_confirm_btn" class="w-full" color="dark">Solicitar</vs-button>
                                                    <vs-button v-else @click.stop="saveDataForm()" :disabled="isDisable" id="4_stakeholders_confirm_btn" class="w-full" color="dark">Autorizar</vs-button>
                                                </div>
                                                <div class="vx-col sm:w-full">
                                                    <div class="flex mb-4">
                                                        <vs-checkbox v-model="advertisingChecked"
                                                            name="advertisingChecked" v-validate="requiredRules"
                                                            :danger="hasError('advertisingChecked')"
                                                            :danger-text="errorText('advertisingChecked')"
                                                            :success="isSuccess('advertisingChecked')"></vs-checkbox>
                                                        <p class="text-sm text-justify black">
                                                            Autorizo a RedGirasol que me envíe publicidad y que use mis datos para mejorar mi experiencia y recibir promociones que se ajusten a mis necesidades.
                                                        </p>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import { ScoreService } from "@/services/score/ScoreService.js"

const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";

export default {
    name: "ApplicantOnboardingCDCRequest",
    props: ["onboardingStepData"],
    mixins: [inputHelper, formatHelper],
    data: () => ({
        requiredRules: 'required',
        colClass: "vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-5 mt-2",
        passInputPassMode: true,
        legal: false,
        errorMssg: null,
        isMounted: false,
        syntageLogo: "https://cdn.redgirasol.com/applicants/syntage_schema.png",
        password: null,
        requiredPlaceholder: "(Requerido)",
        isEditingRFC: false,
        isEditingPhone: false,
        country_calling_code: null,
        currentPhone: null,
        currentRfc: null,
        nipIsCreated: false,
        nipRequested: null,
        applicantContractType: 4,
        applicantContractUrl: 'contrato-de-comision-mercantil-cliente',
        advertisingChecked: true,
        showWhatsapp: false,
        whatsappTime: 30,
        nipIsCreated: false,
        satProfile: null,
    }),
    async mounted(){
        if(this.satProfile != null && (this.satProfile.credential_status == "error" || this.satProfile.credential_status == "invalid")) {
            this.errorMssg = "No hemos podido validar tu cuenta del SAT, por favor verifica tus datos e intenta de nuevo.";
        }
        this.isMounted = true;
    },
    computed: {
        comisionMercantilUrl(){
            return `${this.WebSiteUrl}/${this.applicantContractUrl}`;
        },
        passInputType() {
            return this.passInputPassMode ? "password" : textType;
        },
        passInputIcon() {
            return this.passInputPassMode ? iconEye : iconEyeOff;
        },
        business() {
            return this.onboardingStepData.user.business
        },
        business_owner() {
            return this.business.personal;
        },
        business_address() {
            return this.business.address;
        },
        currentPhoneData() {
            return this.business_owner.phone;
        },
        currentPhoneNumber() {
            return this.currentPhoneData.phone
        },
        dialCode() {
            return this.currentPhoneData.country_calling_code
        },
        isDisable() {
            console.log(this.isEditingPhone, this.password, this.legal, this.nipRequested);
            return this.isEditingPhone || !this.legal || this.nipRequested == null
        },
        lastSatProfile() {
            return this.business.last_sat_profile;
        },
    },
    methods: {
        onSelect({ dialCode }) {
            this.country_calling_code = `+${dialCode}`;
        },
        doRequestSaveData() {

        },
        async saveDataForm() {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                this.missingFieldsNotif();
                return;
            }

            if(!this.legal) {
                this.missingFieldsNotif();
                return;
            }
            
            const payload = {
                applicant_id: this.ApplicantId,
                verification_code: this.nipRequested,
                advertising_checked: this.advertisingChecked,
                contractType: this.applicantContractType
            }
            
            try {
                this.showLoading(true);
                await this.requestScorePM();
                await axios.put(`/api/applicant-onboarding/put/update-sat-profile`, payload);
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (error) {
                this.errorMssg = "No hemos podido realizar la operación, por favor ponte en contacto con nosotros a través del Chat para revisar tu caso."
                console.log(error);
            }
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            return r1;
        },
        editRfc() {
            this.currentRfc = this.business.rfc;
            this.isEditingRFC = true;
        },
        async saveRfc() {
            let validRfc = await this.isValidRfcFormatByPersonType(this.currentRfc, true);
            if (!validRfc) {
                return this.missingFieldsNotif(null, "La estructura de la clave RFC es incorrecta.");
            }
            this.showLoading(true);
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    business: {
                        rfc: this.currentRfc,
                        only_rfc: true
                    }
                };
                await axios.put(
                    `/api/applicant-onboarding/put/update-business`,
                    payload
                );
                await this.$emit("updated", 1);
                this.currentRfc = null;
                this.isEditingRFC = false;
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        editPhone() {
            this.currentPhone = this.currentPhoneNumber;
            this.isEditingPhone = true;
        },
        async changeUserPhone() {
            if(this.currentPhone == null) {
                this.missingFieldsNotif();
                return;
            }
            this.showLoading(true, "Cambiando el teléfono...");
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    phone_data: {
                        phone_id: this.currentPhoneData.id,
                        country_calling_code: this.country_calling_code,
                        phone: this.currentPhone
                    },
                };
                await axios.put(
                    "/api/applicant-onboarding/put/update-applicant-phone",
                    payload
                );

                this.saveSuccessNotif(null, "Teléfono cambiado exitosamente. Te enviamos un código de verificación que usarás a continuación.");
                await this.$emit("updated", 1);
                this.currentPhone = null;
                this.isEditingPhone = false;
                this.nipIsCreated = true;
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        async doRequestCDCNip(isWhatsapp = true)
        {
            try {
                this.showLoading(true, "Enviando código de verificación...")
                const res = await axios.post(`/api/v2/projects/${this.UserId}/send-cdc-nip?whatsapp=${isWhatsapp}`);
                this.nipIsCreated = true;
                if (!this.showWhatsapp) {
                    this.initNipCounter(30);
                }
                this.nipCounter++;
                this.showLoading(false);
                this.saveSuccessNotif(null, `Te hemos enviado un código por mensaje de texto al siguiente número: ${this.currentPhoneData.country_calling_code} ${this.anonymousFormatPhone(this.currentPhoneNumber)}`);
            } catch (error) {
                console.log(error);
            }
        },
        initNipCounter() {
        let timer = setInterval(() => {
                --this.whatsappTime;
                if (this.whatsappTime == 0) {
                    this.showWhatsapp = true;
                    clearInterval(timer);
                }
            }, 1000);
        },
        async requestScorePM() {
            try {
                const payload = {
                    verification_code: this.nipRequested
                }
                const response = await axios.post(`/api/applicant-onboarding/post/request-cdc-pm/${this.ApplicantId}`,  payload);
                if (!response.error) {
                    this.saveSuccessNotif();
                }
            } catch (error) {
                this.failedOperationNotif()
                console.log(error);
            }
        },
        togglePassInputType() {
            this.passInputPassMode = !this.passInputPassMode;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>

<style>

.fix-margin {
    margin: 1rem auto 0 auto !important;
}

.syntage {
    border: 2px solid #C8C8C8;
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    background-color: #c8c8c814;
}

.no-password {
    color: #13640C;
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
}
.content-center {
    place-content: center;
}

span.feather-icon .rfc {
    margin-top: -.5rem;
}
span.feather-icon .phone {
    margin-top: 0rem;
}
</style>