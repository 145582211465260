<template>
    <div id="3_applicant-onboarding-general" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-3/6 xxl:w-1/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <vs-progress :percent="40" :height="10" color="primary"></vs-progress>
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 center">
                                            <div class="vx-col w-full">
                                                <p class="mb-2">PASO 2 DE 5</p>
                                                <p class="text-2xl card-sub-title">Cuéntanos sobre ti</p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div class="main-form mt-base">
                                        <div class="vx-row">
                                            <div :class="colClass">
                                            <vs-input
                                                @input="(val) => (firstName = firstName.toUpperCase())"
                                                class="w-full"
                                                label="Primer nombre"
                                                name="firstName"
                                                v-validate="requiredRules"
                                                v-model.trim="firstName"
                                                :danger="hasError('firstName')"
                                                :danger-text="errorText('firstName')"
                                                :success="isSuccess('firstName')"
                                                icon-pack="feather"
                                                :placeholder="requiredPlaceholder"
                                            />
                                            </div>
                                            <div :class="colClass">
                                            <vs-input
                                                @input="(val) => (secondName = secondName.toUpperCase())"
                                                class="w-full"
                                                label="Segundo nombre"
                                                name="secondName"
                                                v-model.trim="secondName"
                                                :danger="hasError('secondName')"
                                                :danger-text="errorText('secondName')"
                                                :success="isSuccess('secondName')"
                                                icon-pack="feather"
                                                :placeholder="optionalPlaceholder"
                                            />
                                            </div>
                                            <div :class="colClass">
                                            <vs-input
                                                @input="(val) => (lastName1 = lastName1.toUpperCase())"
                                                class="w-full"
                                                label="Primer apellido"
                                                name="lastName1"
                                                v-validate="requiredRules"
                                                v-model.trim="lastName1"
                                                :danger="hasError('lastName1')"
                                                :danger-text="errorText('lastName1')"
                                                :success="isSuccess('lastName1')"
                                                icon-pack="feather"
                                                :placeholder="requiredPlaceholder"
                                            />
                                            </div>
                                            <div :class="colClass">
                                                <vs-input
                                                    @input="(val) => (lastName2 = lastName2.toUpperCase())"
                                                    class="w-full"
                                                    label="Segundo apellido"
                                                    name="lastName2"
                                                    v-model.trim="lastName2"
                                                    :danger="hasError('lastName2')"
                                                    :danger-text="errorText('lastName2')"
                                                    :success="isSuccess('lastName2')"
                                                    icon-pack="feather"
                                                    :placeholder="optionalPlaceholder"
                                                />
                                            </div>
                                            <div :class="colClass">
                                                <vs-select label="Género" name="gender" v-validate="requiredRules"
                                                    :danger="hasError('gender')"
                                                    :danger-text="errorText('gender')"
                                                    :success="isSuccess('gender')"
                                                    placeholder="Selecciona una opción"
                                                    v-model.lazy="gender"
                                                    class="mr-2 w-full">
                                                    <vs-select-item v-for="(item, index) in genderOptions"
                                                        :text="item.label" :key="index"
                                                        :value="item.value"></vs-select-item>
                                                </vs-select>
                                                <small><i>Selecciona el género que está en tu acta de nacimiento.</i></small>
                                            </div>
                                        </div>
                                        <vs-alert
                                            v-if="errorMssg"
                                            icon-pack="feather"
                                            icon="icon-alert-triangle"
                                            class="mb-5"
                                            color="danger"
                                        >
                                            <span class="font-regular">{{ errorMssg }}</span>
                                        </vs-alert>
                                        <div class="justify-center vx-row">
                                            <!-- <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                <vs-button class="w-full mt-4" type="border" color="dark" @click="isMainOnboardingStepVisible = false"
                                                    >Atrás</vs-button>
                                            </div> -->
                                            <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                <vs-button id="3_name_confirm_btn" class="w-full mt-4" color="dark" @click="saveDataForm"
                                                    >Continuar</vs-button>
                                            </div>
                                        </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
const genderOptions = [
    { label: "Mujer", value: 2 },
    { label: "Hombre", value: 1 },
    // { label: "No definido", value: 0 },
    // { label: "No binario", value: 0 },
]
export default {
    name: "ApplicantOnboardingGeneral",
    props: ["onboardingStepData"],
    mixins: [inputHelper],
    data: () => ({
        isMainOnboardingStepVisible: true,
        requiredRules: "required",
        errorMssg: null,
        colClass: "vx-col sm:w-full w-full mb-5",
        requiredPlaceholder: "(Requerido)",
        optionalPlaceholder: "(Opcional)",
        firstName: null,
        secondName: null,
        lastName1: null,
        lastName2: null,
        gender: null,
        genderOptions: genderOptions
    }),
    computed: {
        isMoral() {
            return this.onboardingStepData.user.person_type == 0 || this.onboardingStepData.user.person_type == 3;
        },
        personal() {
            return this.onboardingStepData.user.personal
        },
        business() {
            return this.onboardingStepData.user.business
        },
        business_owner() {
            return this.business.personal
        },
        business_main_user_personal() {
            return this.business.main_user_personal
        },
    },
    beforeMount(){
        this.setData();
    },
    methods: {
        async saveDataForm() {
            this.errorMssg = null;
            if (!(await this.validateForm())) {
                return;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    applicant_id: this.ApplicantId,
                    first_name: this.firstName,
                    second_name: this.secondName,
                    last_name_1: this.lastName1,
                    last_name_2: this.lastName2,
                    gender: this.gender,
                };
                await axios.post(
                    `/api/applicant-onboarding/post/save-general`,
                    payload
                );
                this.showLoading(false);
                this.$emit("updated", 1);
            } catch (e) {
                this.showLoading(false);
                this.showError(e);
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        setData() {
            const personal = this.isMoral ? this.business_main_user_personal : this.personal;
            this.firstName = personal.first_name;
            this.secondName = personal.second_name;
            this.lastName1 = personal.last_name_1;
            this.lastName2 = personal.last_name_2;
            this.gender = personal.gender;
        },
        showError(e) {
            this.warn(e);
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
        let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
    }
}
</script>